import "./App.css";
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { Box } from "@mui/material";
import CustomThemeProvider from "./components/Theme";
import TemporaryDrawer from "./components/sidebar";
import ButtonAppBar from "./components/commonAppbar";
import LoginPage from "./pages/login";
import DashboardPage from "./pages/dashboard";
import LoginTopBar from "./components/loginAppbar";
import WhatsNewCardView from "./pages/WhatNew/viewAllCard";

function AppContent() {
  const location = useLocation();
  const isLoginPage =
    location.pathname === "/login" || location.pathname === "/";

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      {!isLoginPage && (
        <TemporaryDrawer sx={{ height: "100vh", position: "fixed" }} />
      )}

      <Box
        sx={{
          width: "100%",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {isLoginPage ? <LoginTopBar /> : <ButtonAppBar />}
        <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              mt: 7,
              overflowY: "auto", // Scrollable area for main content
              height: "calc(100vh - 64px)", // Adjust height based on AppBar height
            }}
          >
            <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/dashboard" element={<DashboardPage />} />
              <Route path="/viewAll" element={<WhatsNewCardView />} />
            </Routes>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default function App() {
  return (
    <CustomThemeProvider>
      <Router>
        <AppContent />
      </Router>
    </CustomThemeProvider>
  );
}
