const API_URL = "api/nodeapp/whatsnew";

function getHeaders() {
  const token = localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  return headers;
}

export const fetchWhatsNew = async () => {
  const requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  try {
    const response = await fetch(API_URL, requestOptions);

    if (!response.ok) {
      throw new Error(`Error ${response.status}: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

// Function to create a new entry (POST method)
export const createEntry = async (
  title,
  description,
  file,
  screenName,
  featureDate
) => {
  const formData = new FormData();
  formData.append("title", title);
  formData.append("description", description);
  formData.append("file", file);
  formData.append("screenName", screenName);
  formData.append("featureDate", featureDate);

  const requestOptions = {
    method: "POST",
    body: formData,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    redirect: "follow",
  };

  try {
    const response = await fetch(API_URL, requestOptions);

    const result = await response.json();

    if (response.status === 201) {
      return { success: true, data: result };
    } else if (response.status === 400) {
      return { success: false, error: result.message };
    } else {
      return { success: false, error: result.message };
    }
  } catch (error) {
    return { success: false, error: error };
  }
};

// Function to delete an entry by ID
export const deleteEntry = async (id) => {
  const requestOptions = {
    method: "DELETE",
    headers: getHeaders(),
    redirect: "follow",
  };

  try {
    const response = await fetch(`${API_URL}/${id}`, requestOptions);
    const result = await response.json();
    if (response.ok) {
      return { success: true, message: "Deleted Successfully." };
    } else {
      return { success: false, error: result.message };
    }
  } catch (error) {
    return { success: false, error: error };
  }
};

export const updateImage = async (file, id) => {
  const formData = new FormData();
  formData.append("file", file);

  const requestOptions = {
    method: "PUT",
    body: formData,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    redirect: "follow",
  };

  try {
    const response = await fetch(`${API_URL}/image/${id}`, requestOptions);

    const result = await response.json();
    // Check if the response is successful
    if (response.ok) {
      return { success: true, data: result };
    } else if (response.status === 400) {
      return { success: false, error: result.message };
    } else {
      return { success: false, error: result.message };
    }
  } catch (error) {
    return { success: false, error: error.message };
  }
};

export const updateEntry = async (id, updateData) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify(updateData);

  const requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: raw,
    headers: getHeaders(),
    redirect: "follow",
  };

  try {
    const response = await fetch(`${API_URL}/${id}`, requestOptions);
    
    const result = await response.json();

    if (response.ok) {
      return { success: true, data: result };
    } else {
      return { success: false, error: result.message};
    }
  } catch (error) {
    return { success: false, error: error.message };
  }
};