import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
} from "@mui/material";
import { createEntry, updateEntry } from "../api/whatsNewApi";
import CustomSnackbarAlert from "./customAlert";

const WhatsNewFormDialog = ({
  open,
  onClose,
  createOrUpdate,
  entry,
  onSuccess,
}) => {
  const [editData, setEditData] = useState({
    title: "",
    description: "",
    screenName: "",
    featureDate: "",
    image: null,
  });

  const [errors, setErrors] = useState({
    title: "",
    description: "",
    screenName: "",
    featureDate: "",
    image: "",
  });

  // for Alet
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [showAlert, setShowAlet] = useState(false);

  useEffect(() => {
    if (createOrUpdate === "update" && entry) {
      setEditData({
        title: entry.title,
        description: entry.description,
        screenName: entry.screenName,
        featureDate: entry.featureDate,
        image: null, // Do not carry the image in update mode
      });
    } else {
      // Reset fields when in "create" mode
      setEditData({
        title: "",
        description: "",
        screenName: "",
        featureDate: "",
        image: null, // Allow image selection in create mode
      });
    }
  }, [createOrUpdate, entry]);

  const validateForm = () => {
    let isValid = true;
    let formErrors = { ...errors };

    if (!editData.title) {
      formErrors.title = "Title is required";
      isValid = false;
    } else {
      formErrors.title = "";
    }

    if (!editData.description) {
      formErrors.description = "Description is required";
      isValid = false;
    } else {
      formErrors.description = "";
    }

    if (!editData.screenName) {
      formErrors.screenName = "Screen Name is required";
      isValid = false;
    } else {
      formErrors.screenName = "";
    }

    if (!editData.featureDate) {
      formErrors.featureDate = "Feature Date is required";
      isValid = false;
    } else {
      formErrors.featureDate = "";
    }

    if (createOrUpdate === "create" && !editData.image) {
      formErrors.image = "Image is required";
      isValid = false;
    } else {
      formErrors.image = "";
    }

    setErrors(formErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return; // Stop submission if validation fails
    }

    try {
      let result;
      let mes;
      if (createOrUpdate === "create") {
        mes = "Created successfully.";
        result = await createEntry(
          editData.title,
          editData.description,
          editData.image,
          editData.screenName,
          editData.featureDate
        );
      } else if (entry) {
        mes = "Updated successfully.";
        const updateData = {
          title: editData.title,
          description: editData.description,
          screenName: editData.screenName,
          featureDate: editData.featureDate,
        };
        result = await updateEntry(entry._id, updateData);
      }

      if (result.success) {
        onSuccess(mes);
      } else {
        console.log("inside error block");

        setAlertSeverity("error");
        setAlertMessage(result.error);
        setShowAlet(true);
      }
    } catch (error) {}
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setEditData((prev) => ({ ...prev, image: file }));
    }
  };

  const handleCloseSnackbar = () => {
    setShowAlet(false);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          {createOrUpdate === "create" ? "Create Item" : "Edit Item"}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <TextField
              id="title"
              label="Title"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              value={editData.title}
              onChange={(e) =>
                setEditData((prev) => ({ ...prev, title: e.target.value }))
              }
              error={!!errors.title}
              helperText={errors.title}
            />
            <TextField
              id="description"
              label="Description"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              value={editData.description}
              onChange={(e) =>
                setEditData((prev) => ({
                  ...prev,
                  description: e.target.value,
                }))
              }
              error={!!errors.description}
              helperText={errors.description}
            />
            <TextField
              id="screenName"
              label="Screen Name"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              value={editData.screenName}
              onChange={(e) =>
                setEditData((prev) => ({ ...prev, screenName: e.target.value }))
              }
              error={!!errors.screenName}
              helperText={errors.screenName}
            />
            <TextField
              id="featureDate"
              label="Feature Date"
              type="date"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              InputLabelProps={{
                shrink: true,
              }}
              value={editData.featureDate}
              onChange={(e) =>
                setEditData((prev) => ({
                  ...prev,
                  featureDate: e.target.value,
                }))
              }
              error={!!errors.featureDate}
              helperText={errors.featureDate}
            />
            {createOrUpdate === "create" && (
              <div>
                <input
                  accept="image/*"
                  type="file"
                  onChange={handleImageChange}
                  style={{ marginTop: "16px", marginBottom: "16px" }}
                  required
                />
                {errors.image && <p style={{ color: "red" }}>{errors.image}</p>}
              </div>
            )}
            <Box
              sx={{
                marginTop: 2,
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <Button type="submit" variant="contained" color="primary">
                {createOrUpdate === "create" ? "Create" : "Update"}
              </Button>
              <Button onClick={onClose} variant="outlined" color="secondary">
                Close
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
      {/* CustomAlert component */}
      <CustomSnackbarAlert
        message={alertMessage}
        severity={alertSeverity}
        open={showAlert}
        onClose={handleCloseSnackbar}
      />
    </>
  );
};

export default WhatsNewFormDialog;
