// src/pages/LoginPage.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Button, Box, Typography } from "@mui/material";
import { login } from "../api/authApi";
import CustomSnackbarAlert from "../components/customAlert";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  // Alert
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [showAlert, setShowAlet] = useState(false);

  const navigate = useNavigate();

  const handleCloseSnackbar = () => {
    setShowAlet(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await login(username, password);
      if (response.success) {
        localStorage.setItem("token", response.token);
        navigate("/viewAll");
      } else {
        setAlertMessage(response.error || "Login failed");
        setAlertSeverity("error");
        setShowAlet(true);
      }
    } catch (err) {
      setAlertMessage(err);
      setAlertSeverity("error");
      setShowAlet(true);
    }
  };

  return (
    <>
      <Box
        sx={{
          maxWidth: 400,
          margin: "auto",
          padding: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" gutterBottom>
          Login
        </Typography>
        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <TextField
            label="Username"
            variant="outlined"
            fullWidth
            margin="normal"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{ marginTop: 2 }}
          >
            Login
          </Button>
        </form>
      </Box>
      {/* CustomAlert component */}
      <CustomSnackbarAlert
        message={alertMessage}
        severity={alertSeverity}
        open={showAlert}
        onClose={handleCloseSnackbar}
      />
    </>
  );
};

export default LoginPage;
